import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const StatisticSection = () => {
  const {t} = useTranslation();

  return (
    <section className="statistic-ctn">
      <div className="container stat-inner-ctn">
        <div className="text-ctn">
          <StaticImage
            src="../../../images/green-innovations-challenge/stat/stat1.png"
            placeholder="none"
            loading="lazy"
            alt=""
          />
          <h3>{t`green.stat1_title`}</h3>
          <p>{t`green.stat1_text`}</p>
        </div>
        <div className="text-ctn">
          <StaticImage
            src="../../../images/green-innovations-challenge/stat/stat2.png"
            placeholder="none"
            loading="lazy"
            alt=""
          />
          <h3>{t`green.stat2_title`}</h3>
          <p>{t`green.stat2_text`}</p>
        </div>
        <div className="text-ctn">
          <StaticImage
            src="../../../images/green-innovations-challenge/stat/stat3.png"
            placeholder="none"
            loading="lazy"
            alt=""
          />
          <h3>{t`green.stat3_title`}</h3>
          <p>{t`green.stat3_text`}</p>
        </div>
        <div className="text-ctn">
          <StaticImage
            src="../../../images/green-innovations-challenge/stat/stat4.png"
            placeholder="none"
            loading="lazy"
            alt=""
          />
          <h3>{t`green.stat4_title`}</h3>
          <p>{t`green.stat4_text`}</p>
        </div>
      </div>
    </section>
  );
}

export default StatisticSection;
