import { graphql } from 'gatsby';
import GreenInnovationsChallenge from '../views/green-innovations-challenge';

export default GreenInnovationsChallenge;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
