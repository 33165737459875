import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const WhySection = () => {
  const {t} = useTranslation();

  return (
    <section className="container mentoring-new-ctn why-section">
      <div className="theme-ctn">
        <StaticImage
          className="theme1"
          src="../../../images/green-innovations-challenge/theme/leaf1.png"
          alt="Theme 1"
          placeholder="none"
        />
        <StaticImage
          className="theme2"
          src="../../../images/green-innovations-challenge/theme/leaf2.png"
          alt="Theme 2"
          placeholder="none"
        />
      </div>
      <div className="advantages-ctn">
        <h2>{t`green.why_title`}</h2>
        <div className="advantages-grid">
          <div>
            <h4 className="mb-1rem">{t`green.advantage1`}</h4>
            <p>{t`green.advantage1a-text`} <b><a href="#mentors">{t`green.advantage1b-text`}</a></b> {t`green.advantage1c-text`}</p>
          </div>
          <div>
            <h4 className="mb-1rem">{t`green.advantage2`}</h4>
            <p>{t`green.advantage2-text`}</p>
          </div>
          <div>
            <h4 className="mb-1rem">{t`green.advantage3`}</h4>
            <p>{t`green.advantage3-text`}</p>
          </div>
          <div>
            <h4 className="mb-1rem">{t`green.advantage4`}</h4>
            <p>{t`green.advantage4-text`}</p>
          </div>
          <div>
            <h4 className="mb-1rem">{t`green.advantage5`}</h4>
            <p>{t`green.advantage5-text`}</p>
          </div>
          <div className="dark-advantages-grid">
            <h4 className="mb-1rem">{t`green.advantage6`}</h4>
            <p>{t`green.advantage6-text`}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhySection;
