import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import '../styles/btn-styles.css';

const FullBtn = ({ to, children }) => (
  <div className="btn full-btn">
    <Link to={to}>{children}</Link>
  </div>
);
const PropBtn = (props, { children }) => (
  <input type={`${props.type}`} value={`${props.value}`} />
);

const BorderBtn = ({ to, children }) => (
  <div className="btn border-btn">
    <Link to={to}>{children}</Link>
  </div>
);

const NoBorderBtn = ({ to, children }) => (
  <div className="btn no-border-btn">
    <Link to={to}>{children}</Link>
  </div>
);

export { FullBtn, BorderBtn, NoBorderBtn, PropBtn };
