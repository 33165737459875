import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import Modal from 'react-modal';
import closeIcon from '../../../images/general/close-icon.svg';
import { FullBtn } from '../../new-mentoring/components/mentoring-buttons';

const boxStyles = {
  overlay: {
    zIndex: 103
  },
  content: {
    margin: 'auto',
    inset: '0',
    width: '80%',
    maxHeight: '50vh',
    maxWidth: '100rem'
  }
};

const Mentors = () => {
  const { t } = useTranslation();
  const MENTORS_DETAILS = [
    {
      name: t(`mentors.mentor15`),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Łukasz Dziekoński.jpg"
          alt="Łukasz Dziekoński"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-blue"
        />
      ),
      role: t(`mentors.mentor15-position`),
      description: t('mentors.mentor15-description')
    },
    {
      name: t('mentors.mentor5'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/rajmund-martyniuk.jpg"
          alt="Ireneusz Martyniuk"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-blue"
        />
      ),
      role: t(`mentors.mentor5-position`),
      description: t('mentors.mentor5-description')
    },
    {
      name: t('mentors.mentor11'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/Agnieszka Musiał.png"
          alt="Agnieszka Musiał"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-blue"
        />
      ),
      role: t(`mentors.mentor11-position`),
      description: t('mentors.mentor11-description')
    },
    {
      name: t('mentors.mentor7'),
      avatar: (
        <StaticImage
          src="../../../images/mentoring/mentors/olga-czerwinska.jpg"
          alt="Olga Czerwińska"
          placeholder="blurred"
          loading="lazy"
          quality={100}
          className="mentor-border mentor-img mentor-border-light-blue"
        />
      ),
      role: t(`mentors.mentor7-position`),
      description: t('mentors.mentor7-description')
    }
  ];
  const [isModal, setIsModal] = React.useState(false);
  const [currentMentor, setMentor] = React.useState(MENTORS_DETAILS[0]);

  const openModal = (mentor) => {
    setMentor(mentor);
    setIsModal(true);
  };
  return (
    <>
      <Modal
        isOpen={isModal}
        style={boxStyles}
        onRequestClose={() => setIsModal(false)}
        shouldCloseOnOverlayClick={true}>
        <div className="circles green-mentoring-modal">
          <StaticImage
            className="circle-motive-left-mentors-details"
            src="../../../images/green-innovations-challenge/theme/leaf3.png"
            alt="Leaf"
            placeholder="none"
            loading="lazy"
          />
          <StaticImage
            className="circle-motive-right-mentors-details"
            src="../../../images/green-innovations-challenge/theme/leaf4.png"
            alt="Leaf"
            placeholder="none"
            loading="lazy"
          />
          <div className="mentors-ctn">
            <div className="modal-ctn">
              <div className="btn-close-box">
                <button onClick={() => setIsModal(false)}>
                  <img src={closeIcon} alt="close icon" />
                </button>
              </div>
              <div className="container modal-text-avatar-ctn">
                <div className="mask-border">
                  <div className="mask-fill">{currentMentor.avatar}</div>
                </div>
                <div className="text-ctn">
                  <h2>{currentMentor.name}</h2>
                  <p className="mentor-role">{currentMentor.role}</p>
                  <p className="description">{currentMentor.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="circles mentoring-mentors-ctn">
        <div className="container">
          <div className="theme-ctn">
            <StaticImage
              className="theme1"
              src="../../../images/green-innovations-challenge/theme/leaf1.png"
              alt="Theme 1"
              placeholder="none"
            />
            <StaticImage
              className="theme2"
              src="../../../images/green-innovations-challenge/theme/leaf2.png"
              alt="Theme 2"
              placeholder="none"
            />
          </div>
          <h2 className="mentoring-mentors-title">{t`mentoring.mentors-title`}</h2>
          <p className="mentoring-mentors-text">{t`green.mentors_text`}</p>
          <div className="mentoring-mentors-grid">
            <div
              className="shade-on-hover"
              role="button"
              tabIndex={0}
              onClick={() => openModal(MENTORS_DETAILS[0])}
              onKeyDown={() => openModal(MENTORS_DETAILS[0])}>
              <div className="mask-border">
                <div className="mask-fill">
                  <p>
                    <b>{t`mentoring.read-more-mentor-text`}</b>
                  </p>
                </div>
                <StaticImage
                  src="../../../images/mentoring/mentors/Łukasz Dziekoński.jpg"
                  alt="Łukasz Dziekoński"
                  placeholder="blurred"
                  loading="lazy"
                  quality={100}
                  className="mentor-border mentor-img mentor-border-blue"
                />
              </div>
              <h4 className="mb-1rem">{t`green.mentor1`}</h4>
              <p>{t`green.mentor1-text`}</p>
            </div>
            <div
              className="shade-on-hover"
              role="button"
              tabIndex={0}
              onClick={() => openModal(MENTORS_DETAILS[3])}
              onKeyDown={() => openModal(MENTORS_DETAILS[3])}>
              <div className="mask-border">
                <div className="mask-fill">
                  <p>
                    <b>{t`mentoring.read-more-mentor-text`}</b>
                  </p>
                </div>
                <StaticImage
                  src="../../../images/mentoring/mentors/olga-czerwinska.jpg"
                  alt="Olga Czerwińska"
                  placeholder="blurred"
                  loading="lazy"
                  quality={100}
                  className="mentor-border mentor-img mentor-border-light-blue"
                />
              </div>
              <h4 className="mb-1rem">{t`green.mentor2`}</h4>
              <p>{t`green.mentor2-text`}</p>
            </div>
            <div
              className="shade-on-hover"
              role="button"
              tabIndex={0}
              onClick={() => openModal(MENTORS_DETAILS[2])}
              onKeyDown={() => openModal(MENTORS_DETAILS[2])}>
              <div
                className="mask-border"
                role="button"
                tabIndex={0}
                onClick={() => openModal(MENTORS_DETAILS[1])}
                onKeyDown={() => openModal(MENTORS_DETAILS[3])}>
                <div className="mask-fill">
                  <p>
                    <b>{t`mentoring.read-more-mentor-text`}</b>
                  </p>
                </div>
                <StaticImage
                  src="../../../images/mentoring/mentors/Agnieszka Musiał.png"
                  alt="Agnieszka Musiał"
                  placeholder="blurred"
                  loading="lazy"
                  quality={100}
                  className="mentor-border mentor-img mentor-border-blue"
                />
              </div>
              <h4 className="mb-1rem">{t`green.mentor3`}</h4>
              <p>{t`green.mentor3-text`}</p>
            </div>
            <div
              className="shade-on-hover"
              role="button"
              tabIndex={0}
              onClick={() => openModal(MENTORS_DETAILS[1])}
              onKeyDown={() => openModal(MENTORS_DETAILS[1])}>
              <div className="mask-border">
                <div className="mask-fill">
                  <p>
                    <b>{t`mentoring.read-more-mentor-text`}</b>
                  </p>
                </div>
                <StaticImage
                  src="../../../images/mentoring/mentors/rajmund-martyniuk.jpg"
                  alt="Ireneusz Martyniuk"
                  placeholder="blurred"
                  loading="lazy"
                  quality={100}
                  className="mentor-border mentor-img mentor-border-light-blue"
                />
              </div>
              <h4 className="mb-1rem">{t`green.mentor4`}</h4>
              <p>{t`green.mentor4-text`}</p>
            </div>
          </div>
          <div className="more-mentors-btn">
            <FullBtn to="/mentors">{t`green.mentors_read_more`}</FullBtn>
          </div>
        </div>
      </div>
    </>
  );
};

const MentorsSection = () => (
  <section id="mentors" className="mentoring-new-ctn green-mentoring">
    <Mentors />
  </section>
);

export default MentorsSection;
