import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import Video from '../../../components/video';

const WhatIsGreenSection = () => {
  const {t} = useTranslation();

  return (
    <section className="container what-is-green-section">
      <div className="text-ctn">
        <StaticImage
          className="logo"
          src="../../../images/green-innovations-challenge/green-innovation-challeng-logo.png"
          alt="Green Innovations Challenge logo"
          placeholder="none"
          loading="lazy"
        />
        <h2>{t`green.what_is`}</h2>
        <p>{t`green.what_is_text_1a`}<b>{t`green.what_is_text_1b`}</b>{t`green.what_is_text_1c`}<b>{t`green.what_is_text_1d`}</b>{t`green.what_is_text_1e`}</p>
      </div>
      <div>
        <Video src="https://www.youtube.com/embed/BXHPUuVP3Lc" title="Podsumowanie Gali Finałowej Programów Mentoringowych Innovations Hub Foundation 2023"/>
      </div>
    </section>
  );
}

export default WhatIsGreenSection;
