import React from 'react';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const ContactSection = () => {
  const {t} = useTranslation();

  return (
    <section className="incubator-offer green-contact">
      <div className="visit-us-out-ctn" id="contact">
        <div className="container">
          <div className="visit-us-ctn">
            <h2>{t`green.contact_title`}</h2>
            <div className="text-ctn">
                <p>{t`green.contact_write`}</p>
                <a href="mailto:green@innovationshub.pl" className="light-green-color">green@innovationshub.pl</a>
            </div>
            <div className="text-ctn">
              <p>{t`green.contact_rules`}</p>
              <Link
                to="/regulations/mentoring-program"
                style={{textDecoration: 'none'}}
              >
                <span style={{textDecoration: 'underline'}}>{t`green.contact_rules_btn`}</span>
                <span> →</span>
              </Link>
            </div>
            <div className="text-ctn">
              <p>{t`green.contact_register`}</p>
              <Link to="/registration" className="green-btn">
                {t`green.contact_register_btn`}
              </Link>
            </div>
          </div>
        </div>
        <StaticImage
          className="img"
          src="../../../images/green-innovations-challenge/Kacper.png"
          alt="Visit us"
          placeholder="none"
        />
        <div className="white-bg"/>
      </div>
    </section>
  );
}

export default ContactSection;
