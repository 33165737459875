import React from 'react';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

const LandingSection = () => {
  const {t} = useTranslation();

  return (
    <section className="landing-section">
      <div className="container landing-inner-ctn">
        <div className="text-ctn">
          <h1>{t`green.title1`} <span>{t`green.title2`}</span></h1>
          <p>{t`green.description`}</p>
          <Link to="/registration" className="green-btn green-btn-light">
            {t`green.join_us`}
          </Link>
        </div>
      </div>
    </section>
  );
}

export default LandingSection;
