import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {StaticImage} from "gatsby-plugin-image";

const JurorsSection = () => {
  const {t} = useTranslation();

  return (
    <>
      <div className="circles mentoring-new-ctn green-jurors">
        <div className="container mentoring-jurors-ctn">
          <div className="theme-ctn">
            <StaticImage
              className="theme1"
              src="../../../images/green-innovations-challenge/theme/leaf3.png"
              alt="Theme 1"
              placeholder="none"
            />
            <StaticImage
              className="theme2"
              src="../../../images/green-innovations-challenge/theme/leaf4.png"
              alt="Theme 2"
              placeholder="none"
            />
          </div>
          <h2 className="mentoring-jurors-title">{t`green.jurors_title`}</h2>
          <p className="mentoring-jurors-text">{t`green.jurors_text`}</p>
          <div className="mentoring-jurors-grid">
            <div>
              <div className='mask-border'>
                <div className="mask-fill">
                  <p><b>{t`mentoring.read-more-juror-text`}</b></p>
                </div>
                <StaticImage
                  src="../../../images/mentoring/jurors/arvin-khanchandani.jpg"
                  alt={t`mentoring.jurors2`}
                  placeholder="blurred"
                  loading="lazy"
                  quality={100}
                  className="juror-border juror-img juror-border-light-blue"
                />
              </div>
              <h4 className="mb-1rem">{t`mentoring.jurors2`}</h4>
              <p>{t`mentoring.jurors2-text`}</p>
            </div>
            <div>
              <div className='mask-border'>
                <div className="mask-fill">
                  <p><b>{t`mentoring.read-more-juror-text`}</b></p>
                </div>
                <StaticImage
                  src="../../../images/mentoring/jurors/jakub-buzalski.jpg"
                  alt={t`mentoring.jurors3`}
                  placeholder="blurred"
                  loading="lazy"
                  quality={100}
                  className="juror-border juror-img juror-border-blue"
                />
              </div>
              <h4 className="mb-1rem">{t`mentoring.jurors3`}</h4>
              <p>{t`mentoring.jurors3-text`}</p>
            </div>
            <div>
              <div className='mask-border'>
                <div className="mask-fill">
                  <p><b>{t`mentoring.read-more-juror-text`}</b></p>
                </div>
                <StaticImage
                  src="../../../images/mentoring/jurors/iwo-rybacki.jpg"
                  alt={t`mentoring.jurors4`}
                  placeholder="blurred"
                  loading="lazy"
                  quality={100}
                  className="juror-border juror-img juror-border-light-blue"
                />
              </div>
              <h4 className="mb-1rem">{t`mentoring.jurors4`}</h4>
              <p>{t`mentoring.jurors4-text`}</p>
            </div>
            <div>
              <div className='mask-border'>
                <div className="mask-fill">
                  <p><b>{t`mentoring.read-more-juror-text`}</b></p>
                </div>
                <StaticImage
                  src="../../../images/mentoring/jurors/marcin-grudniewski.jpg"
                  alt={t`mentoring.jurors5`}
                  placeholder="blurred"
                  loading="lazy"
                  quality={100}
                  className="juror-border juror-img juror-border-blue"
                />
              </div>
              <h4 className="mb-1rem">{t`mentoring.jurors5`}</h4>
              <p>{t`mentoring.jurors5-text`}</p>
            </div>
            <div>
              <div className='mask-border'>
                <div className="mask-fill">
                  <p><b>{t`mentoring.read-more-juror-text`}</b></p>
                </div>
                <StaticImage
                  src="../../../images/mentoring/jurors/piotr-boulange.jpg"
                  alt={t`mentoring.jurors6`}
                  placeholder="blurred"
                  loading="lazy"
                  quality={100}
                  className="juror-border juror-img juror-border-light-blue"
                />
              </div>
              <h4 className="mb-1rem">{t`mentoring.jurors6`}</h4>
              <p>{t`mentoring.jurors6-text`}</p>
            </div>
            <div>
              <div className='mask-border'>
                <div className="mask-fill">
                  <p><b>{t`mentoring.read-more-juror-text`}</b></p>
                </div>
                <StaticImage
                  src="../../../images/mentoring/jurors/piotr-gebala.jpg"
                  alt={t`mentoring.jurors7`}
                  placeholder="blurred"
                  loading="lazy"
                  quality={100}
                  className="juror-border juror-img juror-border-light-blue"
                />
              </div>
              <h4 className="mb-1rem">{t`mentoring.jurors7`}</h4>
              <p>{t`mentoring.jurors7-text`}</p>
            </div>
            <div>
              <div className='mask-border'>
                <div className="mask-fill">
                  <p><b>{t`mentoring.read-more-juror-text`}</b></p>
                </div>
                <StaticImage
                  src="../../../images/mentoring/jurors/kacper-raciborski.jpg"
                  alt={t`mentoring.jurors8`}
                  placeholder="blurred"
                  loading="lazy"
                  quality={100}
                  className="juror-border juror-img juror-border-blue"
                />
              </div>
              <h4 className="mb-1rem">{t`mentoring.jurors8`}</h4>
              <p>{t`mentoring.jurors8-text`}</p>
            </div>
            <div>
              <div className='mask-border'>
                <div className="mask-fill">
                  <p><b>{t`mentoring.read-more-juror-text`}</b></p>
                </div>
                <StaticImage
                  src="../../../images/mentoring/jurors/jerzy-kalinowski.jpg"
                  alt={t`mentoring.jurors1`}
                  placeholder="blurred"
                  loading="lazy"
                  quality={100}
                  className="juror-border juror-img juror-border-blue"
                />
              </div>
              <h4 className="mb-1rem">{t`mentoring.jurors1`}</h4>
              <p>{t`mentoring.jurors1-text`}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JurorsSection;
