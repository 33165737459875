import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const EkoBusinessSection = () => {
  const {t} = useTranslation();

  return (
    <section className="eko-section">
      <div className="container eko-inner-ctn">
        <div className="text-ctn">
          <div className="icon">
            <StaticImage
              src="../../../images/green-innovations-challenge/icons/radioactive.png"
              alt="Radioactive icon"
              placeholder="none"
              loading="lazy"
            />
          </div>
          <h2>{t`green.eko_title`}</h2>
        </div>
      </div>
    </section>
  );
}

export default EkoBusinessSection;
