import React from 'react';
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const TwoPhaseSection = () => {
  const {t} = useTranslation();

  return (
    <section className="container two-phase-section" style={{paddingTop: 0}}>
      <div className="theme-ctn">
        <StaticImage
          className="theme1"
          src="../../../images/green-innovations-challenge/theme/leaf1.png"
          alt="Theme 1"
          placeholder="none"
        />
        <StaticImage
          className="theme2"
          src="../../../images/green-innovations-challenge/theme/leaf2.png"
          alt="Theme 2"
          placeholder="none"
        />
      </div>
      <div className="steps-ctn">
        <StaticImage
          className="bg-motive"
          src="../../../images/green-innovations-challenge/line.png"
          alt="Background line motive"
          placeholder="none"
          loading="eager"
        />
        <StaticImage
          className="bg-motive-mobile"
          src="../../../images/green-innovations-challenge/line-mobile.png"
          alt="Background line motive"
          placeholder="none"
          loading="eager"
        />
        <h2 className="steps-title">{t`green.title1a-steps`}{t`green.title1b-steps`}{t`green.title1c-steps`}</h2>
        <div className="steps-grid">
          <div>
            <StaticImage
              src="../../../images/green-innovations-challenge/step1.png"
              alt="MedBiz Innovations Program step 1"
              placeholder="none"
              loading="lazy"
            />
            <h4 className="mb-1rem">{t`green.step1`}</h4>
            <p>{t`green.step1a-text`}<b><Link to={'/registration'}>{t`green.step1b-text`}</Link></b>{t`green.step1c-text`}</p>
          </div>
          <div>
            <StaticImage
              src="../../../images/green-innovations-challenge/step2.png"
              alt="MedBiz Innovations Program step 2"
              placeholder="none"
              loading="lazy"
            />
            <h4 className="mb-1rem">{t`green.step2`}</h4>
            <p>{t`green.step2a-text`}<b><Link to={'/news/workshops'}>{t`green.step2b-text`}</Link></b>{t`green.step2c-text`}</p>
          </div>
          <div>
            <StaticImage
              src="../../../images/green-innovations-challenge/step3.png"
              alt="MedBiz Innovations Program step 3"
              placeholder="none"
              loading="lazy"
            />
            <h4 className="mb-1rem">{t`green.step3`}</h4>
            <p>{t`green.step3a-text`}<b><Link to={'/news/articles/jak-stworzyc-teaser-inwestycyjny'}>{t`green.step3b-text`}</Link></b>{t`green.step3c-text`}</p>
          </div>
          <div>
            <StaticImage
              src="../../../images/green-innovations-challenge/step4.png"
              alt="MedBiz Innovations Program step 4"
              placeholder="none"
              loading="lazy"
            />
            <h4 className="mb-1rem">{t`green.step4`}</h4>
            <p>{t`green.step4-text`}</p>
          </div>
          <div>
            <StaticImage
              src="../../../images/green-innovations-challenge/step5.png"
              alt="MedBiz Innovations Program step 5"
              placeholder="none"
              loading="lazy"
            />
            <h4 className="mb-1rem">{t`green.step5`}</h4>
            <p>{t`green.step5a-text`}<b><a href="#mentors">{t`green.step5b-text`}</a></b>{t`green.step5c-text`}</p>
          </div>
          <div>
            <StaticImage
              src="../../../images/green-innovations-challenge/step6.png"
              alt="MedBiz Innovations Program step 6"
              placeholder="none"
              loading="lazy"
            />
            <h4 className="mb-1rem">{t`green.step6`}</h4>
            <p>{t`green.step6-text`}</p>
          </div>
        </div>
        <h4 className="mb-1rem">{t`green.step7`}</h4>
        <p>{t`green.step7a-text`}<b><Link to={'/incubator'}>{t`green.step7b-text`}</Link></b>{t`green.step7c-text`}</p>
      </div>
    </section>
  );
}

export default TwoPhaseSection;
