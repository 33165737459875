import React from 'react';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import {FullBtn} from "../../new-mentoring/components/mentoring-buttons";
import SmallProjectCard from '../../previous-editions/components/small-project-card';
import PROJECTS_2023 from '../../previous-editions/components/projects-2023';
import ProjectModal from '../../previous-editions/components/project-modal';

const ProjectSection = () => {
  const [isModal, setModal] = React.useState(false);
  const [currentProject, setProject] = React.useState(PROJECTS_2023[0])
  const {t} = useTranslation();
  const {language} = React.useContext(I18nextContext);

  return (
    <>
      <ProjectModal isModal={isModal} setIsModal={setModal} project={currentProject} isGreen={true}/>

      <div className="container mentoring-projects-ctn green-projects">
        <h2 className="mentoring-projects-title-big">{t`mentoring.projects-title`}</h2>
        <div className="previous-edition_ctn" style={{paddingTop: 0}}>
          <div className="ctn-2020">
            <div className="left-ctn">
              <div className="project-ctn" >
                <div className="left-ctn">
                  <h3>{PROJECTS_2023[4].name}</h3>
                  <h4>{t`previous-editions.authors-title`}</h4>
                  {PROJECTS_2023[4].authors}
                  <h4>{t`previous-editions.mentors-title`}</h4>
                  {PROJECTS_2023[4].mentors}
                  <h4>{t`previous-editions.tags-title`}</h4>
                  <p className="project-card-tags_ctn">
                    {PROJECTS_2023[4].tags.map(tag =>
                      <>
                        <span key={tag.name}>{tag.name}</span>
                        <span className="bullet"> • </span>
                      </>)}
                  </p>
                </div>
                <button className="right-ctn img-button" onClick={() => {
                  setModal(true);
                  setProject(PROJECTS_2023[4])
                }}>
                  {
                    (language !== 'pl' && PROJECTS_2023[4].img_en !== undefined) ? PROJECTS_2023[4].img_en : PROJECTS_2023[4].img
                  }
                  <div className="img-button-readmore">
                    <p>{t`previous-editions.read-more`}</p>
                  </div>
                </button>
              </div>
            </div>
            <div className="right">
              <SmallProjectCard project={PROJECTS_2023[5]} setProject={setProject} setIsModal={setModal}/>
              <SmallProjectCard project={PROJECTS_2023[6]} setProject={setProject} setIsModal={setModal}/>
              <SmallProjectCard project={PROJECTS_2023[7]} setProject={setProject} setIsModal={setModal}/>
              <FullBtn to="/previous-editions">
                {t`mentoring.more-projects-btn`}
              </FullBtn>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectSection;
