import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const NdaSection = () => {
  const {t} = useTranslation();

  return (
    <section className="container what-is-green-section for-who-section" style={{paddingTop: 0}}>
      <div className="text-ctn">
        <h2>{t`green.nda_title`}</h2>
        <p>{t`green.nda_text_1a`}<b>{t`green.nda_text_1b`}</b>{t`green.nda_text_1c`}<b>{t`green.nda_text_1d`}</b>{t`green.nda_text_1e`}</p>
      </div>
      <div>
        <StaticImage
          src="../../../images/green-innovations-challenge/nda2.png"
          alt="Med girl with book"
          loading="lazy"
          placeholder="none"
        />
      </div>
    </section>
  );
}
export default NdaSection;
