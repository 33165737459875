import React from 'react';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

const ForWhoSection = () => {
  const {t} = useTranslation();

  return (
    <section className="container what-is-green-section for-who-section" style={{paddingBottom: 0}}>
      <div>
        <StaticImage
          src="../../../images/green-innovations-challenge/girl-with-book.png"
          alt="Med girl with book"
          loading="lazy"
          placeholder="none"
        />
      </div>
      <div className="text-ctn">
        <h2>{t`green.for_who_title`}</h2>
        <p>
          {t`green.for_who_text1`}
          <ul><div className="bullet"/>{t`green.for_who_text_ul1`}</ul>
          <ul><div className="bullet"/>{t`green.for_who_text_ul2`}</ul>
          <ul><div className="bullet"/>{t`green.for_who_text_ul3`}</ul>
          <ul><div className="bullet"/>{t`green.for_who_text_ul4`}</ul>
        </p>
        <p>{t`green.for_who_text2`}</p>
        <Link to="/registration" className="green-btn">
          {t`green.for_who_btn`}
        </Link>
      </div>
    </section>
  );
}
export default ForWhoSection;
