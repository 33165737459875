import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const EarthSection = () => {
  const {t} = useTranslation();

  return (
    <section className="eko-section earth-section">
      <div className="container eko-inner-ctn">
        <div className="text-ctn">
          <h2>{t`green.earth_title`}</h2>
          <p>{t`green.earth_text`}</p>
        </div>
      </div>
    </section>
  );
}

export default EarthSection;
