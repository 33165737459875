import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Seo from '../../components/seo';
import ogImage from '../../images/seo/SEO_fundacja.jpg';
import Layout from '../../components/layout/layout';
import './style/style.scss'
import LandingSection from './components/landing-section';
import WhatIsGreenSection from './components/what-is-green-section';
import EkoBusinessSection from './components/eko-bussines-section';
import AdvantagesSection from './components/adventages-section';
import TwoPhaseSection from './components/two-phase-section';
import StatisticSection from './components/statistic-section';
import WhySection from './components/why-section';
import ForWhoSection from './components/for-who-section';
import MentorsSection from './components/mentors-section';
import NdaSection from './components/nda-section';
import EarthSection from './components/earth-section';
import AnimatedRocket from './components/rocket-section';
import JurorsSection from './components/jurors-section';
import ProjectSection from './components/project-sections';
import PartnersSection from './components/partners-section';
import ContactSection from './components/contact-section';

const GreenInnovationsChallenge = () => {

  const {t} = useTranslation();

  return (
    <Layout withHeader={false}>
      <Seo
        title={`${t("green.title1")} ${t("green.title2")}`}
        description={t`green.description`}
        ogImage={ogImage}
      />
      <div className="green-innovations-challenge-ctn">

        <LandingSection/>

        <WhatIsGreenSection/>

        <EkoBusinessSection/>

        <AdvantagesSection/>

        <TwoPhaseSection/>

        <StatisticSection/>

        <WhySection/>

        <EarthSection/>

        <ForWhoSection/>

        <MentorsSection/>

        <NdaSection/>

        <AnimatedRocket/>

        <JurorsSection/>

        <ProjectSection/>

        <PartnersSection/>

        <ContactSection/>
      </div>
    </Layout>
  )
}

export default GreenInnovationsChallenge;
