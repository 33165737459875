import React from 'react';
import SlideToggle from "react-slide-toggle";
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { withPrefix } from 'gatsby';

const AnimatedRocket = () => {
  const {t} = useTranslation();
  const [isWholeScheduleVisible, setIsWholeScheduleVisible] = React.useState(false);
  const [showMoreButton, setshowMoreButton]=React.useState(t('harmonogram.show-more'));
  const showOrHide = () => {
    setIsWholeScheduleVisible(!isWholeScheduleVisible);
    if (showMoreButton === t('harmonogram.show-more')){
      setshowMoreButton(t('harmonogram.show-less'));
    }
    else{
      setshowMoreButton(t('harmonogram.show-more'));
    }
  }

  return (
    <section className="mentoring-new-ctn green-rocket">
      <div className="harmonogram-ctn" id="schedule">
        <div className="container">
          <div className="harmonogram-text-ctn">
            <div className="harmonogram-title-ctn">
              <h2>{t`mentoring.title-harmonogram`}</h2>
            </div>
            <div className="harmonogram-left">
              <h3>{t`green.harmonogram-1-date`}</h3>
              <h4>{t`green.harmonogram-1-title`}</h4>
              <p><Link to="/registration">{t`green.harmonogram-1a-text`}</Link>{t`green.harmonogram-1b-text`}</p>
            </div>
            <div className="harmonogram-right">
              <h3>{t`green.harmonogram-2-date`}</h3>
              <h4>{t`green.harmonogram-2-title`}</h4>
              <p>{t`green.harmonogram-2-text`}</p>
            </div>
            <div className="harmonogram-left">
              <h3>{t`green.harmonogram-3-date`}</h3>
              <h4>{t`green.harmonogram-3-title`}</h4>
              <p>{t`green.harmonogram-3a-text`} <Link to="/news/workshops">{t`green.harmonogram-3b-text`}</Link> {t`green.harmonogram-3c-text`}</p>
            </div>
            <div className="harmonogram-right">
              <h3>{t`green.harmonogram-4-date`}</h3>
              <h4>{t`green.harmonogram-4-title`}</h4>
              <p>{t`green.harmonogram-4a-text`}<Link to="/news/articles/jak-stworzyc-teaser-inwestycyjny">{t`green.harmonogram-4b-text`}</Link>{t`green.harmonogram-4c-text`}</p>
            </div>
          </div>
          <SlideToggle
            collapsed={true}
            duration={500}
            render={({ toggle, setCollapsibleElement, progress }) => (
              <div>
                <div ref={setCollapsibleElement}>
                  <div style={{
                    transform: `translateY(${Math.round(20 * (-1 + progress))}px)`
                  }}
                  >
                    <div className="harmonogram-text-ctn">
                      <div className="harmonogram-left">
                        <h3>{t`green.harmonogram-5-date`}</h3>
                        <h4>{t`green.harmonogram-5-title`}</h4>
                        <p>{t`green.harmonogram-5a-text`}<a href="#mentors">{t`green.harmonogram-5b-text`}</a>{t`green.harmonogram-5c-text`}</p>
                        <div className="rocket-schedule-theme-btn">
                          <a href={withPrefix('MBIP&GIC_wyniki.pdf')}>
                            {t`mentoring.see_results_1`}
                          </a>
                        </div>
                      </div>
                      <div className="harmonogram-right">
                        <h3>{t`green.harmonogram-6-date`}</h3>
                        <h4>{t`green.harmonogram-6-title`}</h4>
                        <p>{t`green.harmonogram-6-text`}</p>
                      </div>
                      <div className="harmonogram-left">
                        <h3>{t`green.harmonogram-7-date`}</h3>
                        <h4>{t`green.harmonogram-7-title`}</h4>
                        <p>{t`green.harmonogram-7-text`}</p>
                      </div>
                      <div className="harmonogram-right">
                        <h3>{t`green.harmonogram-8-date`}</h3>
                        <h4>{t`green.harmonogram-8-title`}</h4>
                        <p>{t`green.harmonogram-8-text`}</p>
                        <div className="rocket-schedule-theme-btn">
                          <a href={withPrefix('Eliminacje_finałowe_2023.pdf')}>
                            {t`mentoring.see_results_2`}
                          </a>
                        </div>
                      </div>
                      <div className="harmonogram-left">
                        <h3>{t`green.harmonogram-9-date`}</h3>
                        <h4>{t`green.harmonogram-9-title`}</h4>
                        <p>{t`green.harmonogram-9-text`}</p>
                        <div className="rocket-schedule-theme-btn">
                          <a href={withPrefix('Wyniki_programow_2023.png')}>
                            {t`mentoring.see_results_3`}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <StaticImage
                  className="rocket-motive-mh"
                  src="../../../images/green-innovations-challenge/rocket_only.png"
                  alt="Rocket motive"
                  placeholder="none"
                  loading="lazy"
                />
                <div className="show-more-btn big-margin">
                  <button className="show-more-btn" onClick={() => {
                    toggle()
                    showOrHide()
                  }}>
                    {showMoreButton}
                  </button>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </section>
  )
}

export default AnimatedRocket;
