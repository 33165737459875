import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Video from '../../../components/video';

const AdvantagesSection = () => {
  const {t} = useTranslation();

  return (
    <section className="container what-is-green-section">
      <div>
        <Video src="https://www.youtube.com/embed/3VzbI0T3bCo"/>
      </div>
      <div className="text-ctn">
        <p>{t`green.eko_text1a`}<b>{t`green.eko_text1b`}</b>{t`green.eko_text1c`}<b>{t`green.eko_text1d`}</b>{t`green.eko_text1e`}</p>
      </div>
    </section>
  );
}

export default AdvantagesSection;
