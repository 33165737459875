import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';

function PartnersSection() {
  const { t } = useTranslation();

  return (
    <>
      <div className="circles mentoring-projects-ctn green-partners">
        <div className="container mentoring-partners-ctn">
          <h2>{t`green.title-partners`}</h2>
          <div className="logo-grid logo-grid-3">
            <a
              href="https://www.gpw.pl/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/gpw.png"
                alt="Giełda Papierów Wartościowych"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
            <a
              href="https://sygnis.pl/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/sygnis.png"
                alt="Sygnis Spółka Akcyjna"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
            <a
              href="https://basck.com/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/basck.png"
                alt="Basck"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
          <h2>{t`green.title-partners-content`}</h2>
          <div className="logo-grid">
            <a
              href="https://innergo.pl/en/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/innergo.png"
                alt="INNERGO."
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
            <a
              href="https://invento.vc/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/invento.png"
                alt="Invento"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
            <a
              href="https://ekipainvestments.pl/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/ekipa.png"
                alt="Ekipa Investments"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
            <a
              href="https://smok.vc/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/smok.png"
                alt="Smok VC"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
            <a
              href="https://montiscap.com/montis-pl/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/montis.png"
                alt="Montis Capital"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
            <a
              href="https://clickmeeting.com/pl"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/clickmeeting.png"
                alt="Click Meeting logo"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
          <div className="logo-grid logo-grid-1">
            <a
              href="https://accelpoint.com/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/accelpoint.png"
                alt="Accelpoint logo"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
          <h2>{t`green.title-partners-patron`}</h2>
          <div className="logo-grid">
            <a
              href="https://uprp.gov.pl/pl"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/uprp.png"
                alt="Urząd Patentowy Rzeczypospolitej Polskiej"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
            <a
              href="https://www.gov.pl/web/ncbr"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/ncbir.png"
                alt="Narodowe Centrum Badań i Rozwoju"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
            <a
              href="https://www.pw.edu.pl/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/pw.png"
                alt="Politechnika Warszawsa"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
            <a
              href="https://www.uw.edu.pl/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/uw.png"
                alt="Uniwersytet Warszawski"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
            <a
              href="https://www.agh.edu.pl/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/agh.png"
                alt="Akademia Górniczo Hutnicza"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
            <a
              href="https://www.sggw.edu.pl/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/sggw.png"
                alt="Szkoła Główna Gospodarstwa Wiejskiego"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
          <div className="logo-grid logo-grid-1">
            <a
              href="https://www.parp.gov.pl/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/parp.png"
                alt="Polska Agencja Rozwoju Przedsiębiorczości"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
          <h2>{t`green.title-partners-media`}</h2>
          <div className="logo-grid logo-grid-4">
            <a
              href="https://www.forbes.pl/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/forbes.png"
                alt="Forbes"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
            <a
              href="https://polskaekologia.org.pl/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/polskaekologia.png"
                alt="Polska Ekologia"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
            <a
              href="https://ekonatura.org.pl/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/ekonatura.png"
                alt="Ekonatura"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
            <a
              href="https://biznesiekologia.com/"
              target="_blank"
              rel="noreferrer nofollow">
              <StaticImage
                src="../../../images/mentoring/partners/biznesekologia.png"
                alt="Biznes Ekologia"
                placeholder="dominantColor"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default PartnersSection;
